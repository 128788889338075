<template>
  <AuctionHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
  <section class="bg-agrogo-green-2">
    <div class="grid grid-cols-3 bg-agrogo-yellow-2 h-20 md:h-24">
      <div class="flex h-auto w-auto items-center justify-end">
        <span
          class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
          >04</span
        >
      </div>
      <div
        class="col-span-2 flex flex-col items-start justify-center ml-5 md:ml-8"
      >
        <span class="font-nexa font-black text-base md:text-2xl mb-3 text-left"
          >Información <span class="font-normal"> de la Subasta</span>
        </span>
        <div class="flex items-center space-x-4 md:space-x-7 md:text-base">
          <span class="icon-punto text-agrogo-green-3"></span>
          <span class="icon-punto text-white"></span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 my-8 ml-14 mr-12 gap-x-2.5">
      <div class="grid grid-cols-1 gap-y-4 mb-6 md:mb-3 md:gap-y-3">
        <div class="grid grid-cols-2 lg:grid-cols-1 gap-x-3 lg:gap-y-3">
          <div
            class="grid grid-cols-1 place-content-center place-items-center lg:justify-items-start lg:grid-cols-2 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
          >
            <p class="font-nexa font-normal text-white text-sm md:text-lg">
              Fecha de inicio
            </p>
            <Datepicker @date="dateDatepicker = $event" class="md:max-w-236" />
          </div>
          <div
            class="grid grid-cols-1 place-content-center place-items-center lg:justify-items-start lg:grid-cols-2 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
          >
            <p class="font-nexa font-normal text-white text-sm md:text-lg">
              Fecha Final
            </p>
            <DatepickerEnd :date="dateDatepicker" class="md:max-w-236" />
          </div>
        </div>
        <div
          class="grid grid-cols-2 place-content-center gap-x-3 md:gap-x-0 lg:grid-cols-2 md:min-h-64 bg-container-steps-auction md:pl-5 md:pr-6"
        >
          <p
            class="font-nexa font-normal text-white text-sm text-left md:text-lg md:leading-none"
          >
            Precio Base <br />
            <span class="font-dinpro text-xs text-agrogo-gray-1"
              >(Pesos Colombianos)
            </span>
          </p>
          <div
            class="flex items-center justify-center lg:justify-start mb-6 md:mb-5"
          >
            <input
              type="number"
              class="input-standard-transparent md:max-w-236"
            />
          </div>
          <div class="flex flex-col items-start justify-center">
            <p class="font-nexa font-normal text-white text-sm md:text-lg">
              Valor de Puja
            </p>
            <div class="hidden md:flex">
              <p
                class="font-dinpro font-normal text-xs text-agrogo-gray-1 text-left"
              >
                (Autocalculado por
                <span class="text-white font-nexa font-black">AGRO GO</span>)
              </p>
            </div>
          </div>
          <div class="flex items-center justify-center lg:justify-start">
            <input type="number" class="input-standard-gray md:max-w-236" />
          </div>
        </div>
        <div class="md:hidden">
          <p
            class="font-dinpro font-normal text-xs text-agrogo-gray-1 text-left"
          >
            (Autocalculado por
            <span class="text-white font-nexa font-black">AGRO GO</span>)
          </p>
        </div>
      </div>
      <div class="border border-agrogo-gray-2 mr-8 mb-6 md:hidden"></div>
      <div
        class="grid grid-cols-1 gap-y-4 md:pb-6 bg-container-steps-auction md:pl-5 md:pr-6 md:pt-5"
      >
        <p
          class="font-nexa font-bold text-base text-agrogo-gray-1 text-left md:text-lg"
        >
          Cálculo de la Comisión
        </p>
        <p
          class="font-dinpro font-normal text-sm text-white text-left opacity-75"
        >
          <span class="icon-subastas mr-1 md:mr-2"></span> Bovino, Puro 5%
        </p>
        <div class="grid grid-cols-2 place-content-center">
          <p
            class="font-nexa font-normal text-agrogo-gray-1 text-sm text-left md:text-base md:leading-none"
          >
            Precio Base <br />
            <span class="font-dinpro text-xs">(Pesos Colombianos) </span>
          </p>
          <div class="flex items-center justify-center">
            <input type="number" class="input-standard-gray md:max-w-236" />
          </div>
        </div>
        <div class="grid grid-cols-2 place-content-center">
          <p
            class="font-nexa font-normal text-agrogo-gray-1 text-sm text-left md:text-base md:leading-none"
          >
            Comisión de Base <br />
            <span class="text-white font-nexa font-black text-xs md:text-base"
              >de AGRO GO</span
            >
            <span class="font-dinpro text-xs text-agrogo-gray-1 md:text-sm">
              (5%)
            </span>
          </p>
          <div class="flex items-center justify-center">
            <input type="number" class="input-standard-gray md:max-w-236" />
          </div>
        </div>
        <p
          class="font-dinpro font-normal text-xs text-white opacity-75 text-left leading-none"
        >
          La comisión es asumida por parte del vendedor.
        </p>
        <div class="grid grid-cols-2 place-content-center">
          <p
            class="font-nexa font-normal text-agrogo-gray-1 text-sm text-left md:text-base md:leading-none"
          >
            Retención de la Fuente
            <span class="font-dinpro text-xs"> (11%)</span>
          </p>
          <div class="flex items-center justify-center">
            <input type="number" class="input-standard-gray md:max-w-236" />
          </div>
        </div>
        <p
          class="font-dinpro font-normal text-xs text-white text-left leading-none"
        >
          Unicamente aplica si el ganador de la subasta es autorretenedor, según
          Ley xxx.
        </p>
        <div class="border border-agrogo-gray-2 mr-8 md:mr-0"></div>
        <div class="grid grid-cols-2 place-content-center">
          <p
            class="font-nexa font-normal text-agrogo-gray-1 text-sm text-left md:text-base md:leading-none"
          >
            Ingreso Base <br />
            <span class="font-dinpro text-xs">(Pesos Colombianos) </span>
          </p>
          <div class="flex items-center justify-center">
            <input type="number" class="input-standard-gray md:max-w-236" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-agrogo-yellow-1 h-14 md:h-20 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button @click="previus()" type="button" class="btn-primary">
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button @click="next()" type="button" class="btn-primary">
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
        ></span>
      </button>
    </div>
  </section>
</template>
<script>
import AuctionHeader from "@/components/header/HeaderAuction";
import Datepicker from "../../global/datepicker/Datepicker";
import DatepickerEnd from "../../global/datepickerEnd/DatepickerEnd";
export default {
  components: {
    AuctionHeader,
    Datepicker,
    DatepickerEnd,
  },
  data() {
    return {
      dateDatepicker: null,
    };
  },
};
</script>
