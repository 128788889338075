<template>
  <Datepicker
    v-model="date"
    locale="es"
    calendarCellClassName="dp-custom-cell"
    :enableTimePicker="false"
    autoApply
    :minDate="minDate()"
    rangeSeparator="0px"
    :format="format"
    placeholder="dd/mm/aaaa"
  />
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { mapState, mapActions } from "vuex";

export default {
  components: { Datepicker },
  computed: {
    ...mapState("datepickerAuction", ["dateSimple"]),
  },
  data() {
    return {
      /**
       * This variable stores date of the multiple datepicker
       * @type {Array}
       */
      date: this.dateSimple,
    };
  },
  methods: {
    ...mapActions("datepickerAuction", ["actionDateSimple"]),
    /**
     * This function formats the date of the datapicker multiple when selected
     * @returns  { Date } returns a Date, which is the date in format day/month/year
     */
    format() {
      const me = this;
      const date = new Date(me.date);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    /**
     * This function validates the minimum day that a user can select with the datepicker
     * @returns { Date } return a date, is the minimum date that can be selected in the datepicker component
     */
    minDate() {
      var date = new Date();
      date.setDate(date.getDate() + 8);
      return date;
    },
  },
  watch: {
    /**
     * This function is watching if the state of the dateSimple mapState changes its value to be assigned in the date attribute of the multiple datepicker component.
     * @returns { Void } return void
     */
    date() {
      const me = this;
      this.actionDateSimple(me.date);
    },
  },
};
</script>
<style>
/* Datepicker */
.dp__month_year_select:hover,
.dp__overlay_col :hover,
.dp__overlay_cell_active,
.dp__inner_nav:hover,
.dp__icon:hover,
.dp__overlay_cell_disabled:hover,
.dp__overlay_cell :hover {
  background-color: #f5ce3e;
  color: #153e35 !important;
}
.dp__overlay_cell_pad,
.dp__cell_inner,
.dp__instance_calendar,
.dp__calendar_header_item {
  font-family: "DINPro" !important;
}
.dp__overlay,
.dp__overlay_action {
  background: radial-gradient(circle at top left, #153e35, #071b16) !important;
  color: white;
}
.dp__cell_disabled,
.dp__cell_inner,
.dp__overlay_cell_disabled,
.dp__overlay_cell {
  background-color: #153e35;
  color: white;
}
.dp__instance_calendar {
  background: radial-gradient(circle at top left, #153e35, #071b16) !important;
}
.dp__arrow_top {
  display: none;
}
.dp__icon {
  color: #f5ce3e;
}
.dp__month_year_col_nav :hover {
  border-radius: 50%;
}
.dp-custom-cell {
  border-radius: 8px;
  color: white;
}
.dp__cell_offset {
  border-radius: 8px;
  color: rgb(161, 161, 161);
}
.dp__calendar_header_item {
  color: #00754a;
  font-style: normal;
}
.dp__month_year_select {
  color: white;
}
.dp__active_date {
  background-color: #f5ce3e;
  color: #153e35;
}
.dp__today {
  background-color: #f5ce3e !important;
  color: #153e35 !important;
}
.dp-custom-menu {
  border: 1px solid #f5ce3e !important;
}
.dp__theme_light {
  border: 1px solid #153e35 !important;
}
.dp__calendar_header_separator {
  display: none;
}

.dp__overlay_cell {
  background-color: #153e35;
}
.dp__overlay_cell_disabled {
  background-color: transparent;
}

.dp__cell_disabled,
.dp__cell_disabled:hover {
  height: 40px;
  width: 40px;
  background-color: transparent;
}

@media (min-width: 768px) {
  .dp__calendar_header_item {
    width: 48px;
  }
  .dp__instance_calendar {
    width: 352px;
    height: 341px;
  }
}

/* input de Datepicker */

.dp__input {
  background-color: transparent;
  border-color: #f5ce3e;
  color: #ffffff;
  font-family: Dinpro;
  font-size: 12px;
  height: 1.375rem;
  border-radius: 0.75rem;
}
.dp__input::placeholder {
  color: #ffffff !important;
}

.dp__input:focus {
  border: 0.125rem solid #f5ce35 !important;
  outline: 0 none;
  box-shadow: none;
  background-color: transparent !important;
}

.dp__input::-webkit-calendar-picker-indicator {
  filter: invert(100%); /* CAMBIA EL ICONO DE CALENDARIO A BLANCO*/
  cursor: pointer;
}

.dp__input::-webkit-clear-button {
  display: none;
}

.dp__input_icon {
  right: 3px;
  left: auto;
  color: white;
}

.dp__clear_icon {
  display: none;
}

@media (min-width: 768px) {
  .dp__input {
    font-size: 18px;
    height: 32px;
    border-radius: 1rem;
  }
  .dp__input_icon {
    right: 5px;
  }
}
.dp__input {
  padding-left: 13px;
}
</style>
